@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 2000px;
  background-color: #181818;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  font-size: 2em;
  text-transform: uppercase;
  color: #d73800;
}
h2 {
  font-size: 1.5em;
  text-transform: uppercase;
  color: #d73800;
}

.error-border {
  border: solid 2px red;
}

.animation-slideInUp,
.animation-slideInLeft,
.animation-slideInRight {
  opacity: 0;
}

.animate .animation-slideInUp {
  animation: slideInUp 1s ease-out forwards;
}

.animate .animation-slideInLeft {
  animation: slideInLeft 1s ease-out forwards;
}

.animate .animation-slideInRight {
  animation: slideInRight 1s ease-out forwards;
}
