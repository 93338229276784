.primaryButton {
    background-color: #D73800;
    color: white;
    padding: 0.5em 1em;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    overflow: hidden;
    text-transform: uppercase;
  }
 
  .secondaryButton {
    background-color: black;
    color: #FCC6B0;
    padding: 0.5em 1em;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #D73800;
    transition: all 0.3s ease;
    overflow: hidden;
  }
  
  .primaryButton:hover, .secondaryButton:hover {
    background-color: #FCC6B0;
    box-shadow: 0px 2px 10px 5px #D73800;
    color: black;
  }
  